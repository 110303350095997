<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/5
  * @BSD
-->
<template>
  <div class="girl-box-page-wrap">
    <!-- 顶部 -->
    <top-bar-black />

    <div class="main">
      <div class="goods-wrap">
        <div class="level-0">
          <div class="left">
            <div class="line1">{{ mainProduct.name }}</div>
            <div class="line2">
              <van-swipe class="swiper" vertical :autoplay="1500" :duration="200" :show-indicators="false">
                <template v-for="(item, index) in newsList">
                  <van-swipe-item :key="index">
                    <div class="wrap">
                      <div class="line2-left">{{ item.nickname }}</div>
                      <div class="line2-right">{{ item.product_name }}</div>
                    </div>
                  </van-swipe-item>
                </template>
              </van-swipe>
            </div>
            <div class="line3">
              <div class="line3-1">*</div>
              <div class="line3-2">本页面商品均可由 39 元盲盒中抽得商品显示价格为零售价</div>
            </div>
            <div class="line4" @click="gotoChance">详细商品概率>></div>
          </div>
          <div class="right" @click="open(mainProduct.product_info)">
            <img class="product-img" :src="mainProduct.product_info ? mainProduct.product_info.small_image_url : ''" mode="" />
            <div class="title">{{ mainProduct.product_info ? mainProduct.product_info.product_market_price_float : '' }}</div>
          </div>
        </div>
        <div class="level-1" v-if="goodsData[0]">
          <div class="leve-scroll">
            <div class="level-list">
              <template v-for="(item, index) in goodsData[0].product_list">
                <div class="level-item" :key="index" @click="open(item)">
                  <img :src="item.small_image_url" mode="" />
                  <div class="text">¥ 129</div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="level-2" v-if="goodsData[1]">
          <div class="level-title">{{ getPrice(goodsData[1].name) }}</div>
          <div class="leve-scroll">
            <div class="level-list">
              <template v-for="(item, index) in goodsData[1].product_list">
                <div class="level-item" :key="index" @click="open(item)">
                  <img :src="item.small_image_url" mode="" />
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="level-3" v-if="goodsData[2]">
          <div class="level-title">{{ getPrice(goodsData[2].name) }}</div>
          <div class="leve-scroll">
            <div class="level-list">
              <template v-for="(item, index) in goodsData[2].product_list">
                <div class="level-item" :key="index" @click="open(item)">
                  <img :src="item.small_image_url" mode="" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <product-detail ref="productDetail" :curProduct="curProduct"/>
  </div>
</template>

<script>
  import { getProductGroupList, getGroupInfo, getProductList, getDetail } from '../../common/api/shop'
  import topBarBlack from '../../components/top-bar-black'
  import productDetail from '../../components/product-detail'
  export default {
    components:{
      topBarBlack,
      productDetail
    },
    data() {
      return {
        activeItem: 0,
        item: { id: 1 },
        goodsData: [],
        commentList: [],
        mainProduct: {},
        newsList: [],
        curProduct: {}
      };
    },
    mounted () {
      this.init()
    },
    methods: {
      /**
       * @description 初始化数据
       * @param void
       * @author 肖
       * @create_date 2021/6/5
       * @return void
       * @BSD
      */
      init () {
        getProductGroupList({
          data: {
            group_id: this.item.id || 1
          },
          success: res => {
            if (res.code == 200) {
              this.goodsData = res.data
              console.log(this.goodsData)
            }
          }
        })


        getGroupInfo ({
          data: {
            group_id: this.item.id
          },
          success: res => {
            if (res.code == 200) {
              this.mainProduct = res.data
            }
          }
        })

        getProductList({
          success: res => {
            if (res.code == 200) {
              this.newsList = res.data.list
            }
          }
        })
      },
      open (item) {
        getDetail({
          data: {
            product_id: item.product_id
          },
          success: res => {
            if (res.code == 200) {
              this.curProduct = res.data
              this.$refs.productDetail.open()
            }
          }
        })
      },
      getPrice(string) {
        return '¥' + string.replace('元','')
      },
      gotoChance () {
        this.$router.push({
          name: 'chance'
        })
        // uni.navigateTo({
        //   url:'/pagesGirl/chance/chance'
        // })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "./boxGoodsList.scss"
</style>

