<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/5
  * @BSD
-->
<template>
  <!-- 详情 -->
  <van-popup v-model="show" position="bottom" class="detail-popup" :safe-area-inset-bottom="false" background-color="#fff">
    <div class="popup-main">
      <div class="close-wrap" @click="close">返回</div>
      <div class="title">商品详情</div>
      <div class="goods-item">
        <div class="left">
          <img :src="curProduct.small_image_url" mode="" />
        </div>
        <div class="right">
          <div class="line1">{{ curProduct.name }}</div>
          <div class="line2">
            <div class="text1">价格</div>
            <div class="text2">￥{{ curProduct.market_price }}</div>
          </div>
        </div>
      </div>
      <div class="goods-content" v-if="curProduct.image_url && curProduct.image_url.length > 0">
        <div class="detail-scroll">
          <template v-for="(item, index) in curProduct.image_url">
            <img :src="item" mode="widthFix" :key="index" />
          </template>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { getDetail } from '../common/api/shop'
export default {
  name: "productDetail",
  props: {
    curProduct: [Object]
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    close () {
      this.show = false
    },
    open () {
      this.show = true
    }
  }
}
</script>

<style scoped lang="scss">
  $mainColor: #EE7F18;
  .detail-popup {
    background: #fff;
    border-radius: 36px 36px 0 0;
    width: 100%;
    box-sizing: border-box;
    height: calc(93vh);
    border: none;
    & .popup-main {
      background: #FFFFFF;
      border-radius: 36px 36px 0 0;
      max-height: calc(93vh);
      box-sizing: border-box;
      padding: 46px 40px 0;
      position: relative;
      & .close-wrap {
        position: absolute;
        color: #BFBFBF;
        font-size: 30px;
        z-index: 20;
      }
      & .title {
        font-size: 36px;
        color: $mainColor;
        line-height: 36px;
        text-align: center;
        font-weight: bold;
        height: 36px;
        margin-bottom: 32px;
      }
      & .goods-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & .left {
          font-size: 0;
          margin-right: 24px;
          & img {
            width: 176px;
            height: 176px;
          }
        }
        & .right {
          & .line1 {
            font-size: 32px;
            color: #3B3A43;
            font-weight: bold;
            // height: 88px;
            margin-bottom: 20px;
          }
          & .line2 {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            & .text1 {
              font-size: 24px;
              color: #CEC2CE;
              line-height: 24px;
              margin-right: 8px;
            }
            & .text2 {
              font-size: 40px;
              color: $mainColor;
              line-height: 40px;
              font-weight: bold;
            }
          }
        }
      }
      & .goods-content {
        font-size: 0;
        margin-top: 32px;
        & img {
          width: 670px;
        }
        & .detail-scroll {
          max-height: calc(93vh - 330px);
          overflow-y: auto;
        }
      }
    }
  }

</style>
